<template>
  <div class="PaperSubmit">
    <div class="index_banner">
        <img src="http://edu.kexin360.com/images/banner11.png" alt="">
        <div class="fluid container">
            <div>
                <p>全国高校论文送审平台</p>
                <span>全国高校论文送审平台是利用互联网、区块链等信息技术手段提供远程互助云评审功能的云平台，为协助学位授予单位构建论文质量保障评价与协同机制，提高学位论文评审的质量和效率。全国高校论文送审平台结合现有的多种评审模式，协助学位授予单位完成各类导师遴选材料、学位点评估材料及学位论文评审工作。</span>
            </div>
        </div>
    </div>
    <div class="fluid container">
      <div class="wrapper">
          <div class="news">
              <div class="newsContent">
                  <div class="wrapper">
                      <div class="hot_l">
                          <div class="container">
                              <div class="hot_title">论文评审公共服务平台</div>
                              <ul>
                                  <li><a href="javascript:;"><img src="http://edu.kexin360.com/images/index01.png" alt=""></a></li>
                                  <li><a href="javascript:;"><img src="http://edu.kexin360.com/images/index02.png" alt=""></a></li>
                                  <li><a href="javascript:;"><img src="http://edu.kexin360.com/images/index03.png" alt=""></a></li>
                                  <li><a href="javascript:;"><img src="http://edu.kexin360.com/images/index04.png" alt=""></a></li>
                                  <li><a href="javascript:;"><img src="http://edu.kexin360.com/images/index05.png" alt=""></a></li>
                                  <li><a href="javascript:;"><img src="http://edu.kexin360.com/images/index06.png" alt=""></a></li>
                                  <li><a href="javascript:;"><img src="http://edu.kexin360.com/images/index07.png" alt=""></a></li>
                                  <li><a href="javascript:;"><img src="http://edu.kexin360.com/images/index08.png" alt=""></a></li>
                                  <li><a href="javascript:;"><img src="http://edu.kexin360.com/images/index09.png" alt=""></a></li>
                              </ul>
                              <li class="clearfix"></li>
                          </div>
                      </div>
                      <div class="hot_r">
                          <div class="hot_title2"><span>论文下载</span><a href="javascript:;" class="title_more">更多></a></div>
                          <div class="container">
                              <div class="li-content">
                                  <ul>
                                      <li class="li-item">
                                          <span class="order">1</span><a class="hot-item" href="javascript:;" >计算机技术在环境污染源信息化管理中的应用</a>
                                      </li>
                                      <li class="li-item">
                                          <span class="order">2</span><a class="hot-item" href="javascript:;" >大数据背景下的高校思政工作创新路径探索</a>
                                      </li>
                                      <li class="li-item">
                                          <span class="order">3</span><a class="hot-item" href="javascript:;" >智轨车站服务系统设计与应用研究</a>
                                      </li>
                                      <li class="li-item">
                                          <span class="order">4</span><a class="hot-item" href="javascript:;" >电子技术专业的主要研究目标和培养方向</a>
                                      </li>
                                      <li class="li-item">
                                          <span class="order">5</span><a class="hot-item" href="javascript:;" >BIM在中职课程体系教学改革中的应用</a>
                                      </li>
                                      <li class="li-item">
                                          <span class="order">6</span><a class="hot-item" href="javascript:;" >信息系统容灾中心的设计与实施</a>
                                      </li>
                                      <li class="li-item">
                                          <span class="order">7</span><a class="hot-item" href="javascript:;" >DRGs付费模式对三级医院信息化建设的影响分析</a>
                                      </li>
                                      <li class="li-item">
                                          <span class="order">8</span><a class="hot-item" href="javascript:;" >电力电子系统建模关键技术综述及展望</a>
                                      </li>
                                      <li class="li-item">
                                          <span class="order">9</span><a class="hot-item" href="javascript:;" >计算机专业教育与创新创业教育融合模式探究</a>
                                      </li>
                                      <li class="li-item">
                                          <span class="order">10</span><a class="hot-item" href="javascript:;" >人工智能时代大学生网络价值观的重构与培育</a>
                                      </li>
                                      <li class="li-item">
                                          <span class="order">11</span><a class="hot-item" href="javascript:;" >高校Java程序设计课程教学改革的初探</a>
                                      </li>
                                  </ul>
                                  <div class="clearfix"></div>
                              </div>
                          </div>
                      </div>
                      <div class="clearfix"></div>
                  </div>
              </div>
          </div>

          <div class="news_type">
              <div class="wrapper">
                  <div class="type_inner">
                      <div class="flex">
                          <div class="type_li">
                              <div class="hot_title2"><span>论文查重常见问题</span><a href="javascript:;" class="title_more">更多></a></div>
                              <ul>
                                  <li><a href="javascript:;" >论文查重是免费的吗？</a></li>
                                  <li><a href="javascript:;" >哪个系统查重比较好？</a></li>
                                  <li><a href="javascript:;" >专业论文怎么查重</a></li>
                                  <li><a href="javascript:;" >研究生论文查重都查什么？</a></li>
                                  <li><a href="javascript:;" >论文查重结果不准确怎么办？</a></li>
                                  <li><a href="javascript:;" >哪些内容不属于查重范畴</a></li>
                              </ul>
                          </div>
                          <div class="type_li">
                              <div class="hot_title2"><span>论文检测常识</span><a href="javascript:;" class="title_more">更多></a></div>
                              <ul>
                                  <li><a href="javascript:;" >论文检测流程</a></li>
                                  <li><a href="javascript:;" >论文检测内容是哪些</a></li>
                                  <li><a href="javascript:;" >系统检测是否准确</a></li>
                                  <li><a href="javascript:;" >论文检测不通过怎么办？</a></li>
                                  <li><a href="javascript:;" >如何查看检测报告</a></li>
                                  <li><a href="javascript:;" >论文检测之后是否能修改二次检测？</a></li>
                              </ul>
                          </div>
                          <div class="type_li">
                              <div class="hot_title2"><span>检测系统使用帮助</span><a href="javascript:;" class="title_more">更多></a></div>
                              <ul>
                                  <li><a href="javascript:;" >论文查重修改文档如何保存</a></li>
                                  <li><a href="javascript:;" >如何查看已检测的论文</a></li>
                                  <li><a href="javascript:;" >无法查看检测报告怎么办？</a></li>
                                  <li><a href="javascript:;" >检测系统显示异常</a></li>
                                  <li><a href="javascript:;" >无法登录检测系统</a></li>
                                  <li><a href="javascript:;" >如何下载检测报告</a></li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div class="article_partner" style="display: none;">
              <div class="container">
                  <div class="propaganda_title">合作单位</div>
                  <ul class="row">
                      <li class="col-lg-3"><img src="http://edu.kexin360.com/images/propaganda/propaganda_img14.jpg" alt=""><p>中国大百科全书数据库</p></li>
                      <li class="col-lg-3"><img src="http://edu.kexin360.com/images/propaganda/propaganda_img15.jpg" alt=""><p>武汉大学</p></li>
                      <li class="col-lg-3"><img src="http://edu.kexin360.com/images/propaganda/propaganda_img16.jpg" alt=""><p>中国数字图书馆</p></li>
                      <li class="col-lg-3"><img src="http://edu.kexin360.com/images/propaganda/propaganda_img17.jpg" alt=""><p>万方数据知识服务平台</p></li>
                      <li class="clearfix"></li>
                  </ul>
              </div>
          </div>
          
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'PaperSubmit'
}
</script>

<style scoped>
a{text-decoration: none;}
.index_banner{position: relative;width: 100%;}
.index_banner img{width: 100%;height: 500px;display: block;margin: 0 auto;}
.index_banner .container{position: absolute;left: 0;top: 0;right: 0;bottom: 0;margin: auto;}
.index_banner .container div{width: 500px;height: 100%;background: transparent;padding: 80px 30px;}
.index_banner p{font-size: 34px;font-family: 'FZZongYi-M05S';font-weight: 400;color: #FFFFFF;margin-bottom: 20px;}
.index_banner span{display: block;font-size: 16px;font-family: 'FZSongHei-B07S';font-weight: 400;line-height: 34px;color: #FFFFFF;}
.hot{margin: 10px 0 20px 0;}
.hot_l{width: 668px;background: #fff;float: left;padding-bottom: 20px;padding-top: 10px;}
.hot_l .container,.hot_r .container{width: 100%;}
.hot_title{height: 40px;line-height: 40px;border-bottom: 1px solid #0043AE;margin-bottom: 10px;}
.hot_title img{vertical-align: middle;margin-right: 10px;}
.hot_title span{vertical-align: middle;font-size: 16px;font-family: Microsoft YaHei;font-weight: bold;line-height: 40px;color: #0149A7;}
.hot_title2{height: 40px;line-height: 40px;background:#0043AE;margin-bottom: 20px;padding: 0 15px;}
.hot_title2 img{vertical-align: middle;margin-right: 10px;}
.hot_title2 span{vertical-align: middle;font-size: 16px;font-family: Microsoft YaHei;font-weight: bold;line-height: 40px;color: #fff;}
.hot .hot_l ul li{height: 34px;line-height: 34px;border-bottom: 1px dashed #E4E4E4;font-size: 14px;color: #3E3E3E;padding-left: 20px;position: relative;}
.hot .hot_l ul li a{color: #3E3E3E;width: 100%;height: 100%;display: inline-block;}
.hot .hot_l ul li::before{content: '';width: 3px;height: 3px;border-radius: 50%;background: #0149A7;position: absolute;left: 0;top: 16px;}
.hot .hot_l ul li span{float: right;}
.hot_r{width: 349px;background: #fff;float: right;padding-bottom: 20px;padding-top: 10px;}
.hot .hot_r a{display: block;width: 320px;height: 68px;background: #0149A7;margin: 40px auto 0 auto;text-align: center;padding: 5px 0;}
.hot .hot_r a img{vertical-align: middle;margin-right: 15px;}
.hot .hot_r a div{vertical-align: middle;display: inline-block;width: 140px;font-size: 30px;font-family: 'PingFang SC';font-weight: 400;color: #FFFFFF;letter-spacing: 4px;}
.hot .hot_r a div span{font-size: 12px;white-space: nowrap;float: left;letter-spacing: 2px;}
.hot .hot_r p{font-size: 14px;font-family: 'PingFang SC';font-weight: 400;line-height: 30px;color: #3E3E3E;width: 320px;margin: 0 auto;padding: 20px 0;}
.hot .hot_r p:nth-child(2){border-bottom: 2px solid #B7B7B7;}
.hot .hot_r p:nth-child(3){padding-bottom: 0;}

.news{margin-bottom: 30px;margin-top: 30px;}
.news_title{height: 84px;background: #0149A7;margin-bottom: 10px;}
.news_title img{width: 100%;height: 100%;}
.news_title li{float: left;}
.news_title a{display: block;font-size: 20px;font-family: 'FZSongHei-B07S';font-weight: 400;color: #FFFFFF;width: 100%;height: 100%;display: block;}
.news_title a.active{background: #fff;color: #0149A7;}
.news_l{float: left;}
.news_l .news_l_img{width: 266px;height: 162px;margin: 15px 0 5px 0;border-radius: 6px;}
.news_l p{font-size: 14px;font-family: 'PingFang SC';font-weight: 400;line-height: 24px;color: #0149A7;}
.news_l span{font-size: 14px;font-family: 'PingFang SC';font-weight: 400;line-height: 24px;color: #8C8C8C;}

.news_r{float: right;width: 320px;padding-top: 15px;}
.news-content .news-item {display: block;width: 100%;margin-bottom: 8px;overflow: hidden;height: 20px;line-height: 20px;padding-left: 15px;position: relative;}
.news-content .news-item::before{content: '';position: absolute;left: 0;top: 5px;width: 0;height: 0;border-top: 5px solid transparent;border-bottom: 5px solid transparent;
  border-right: 5px solid transparent;border-left: 5px solid #0043AE;}
.news-content .news-item .classfication {float: inherit;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;float: left;display: block;height: 14px;
    line-height: 14px;background: rgba(0, 81, 186, 0.1);border-radius: 2px;font-size: 12px;font-family: 'PingFangSC-Regular, PingFang SC';font-weight: 400;
    color: #0051BA;padding: 3px 8px;text-align: center;margin-right: 4px;height: 20px;}
.news-content .news-item a {display: inline-block;font-family: 'PingFangSC-Regular, PingFang SC';font-size: 14px;max-width: 300px;height: 20px;line-height: 20px;
    float: inherit;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;color: rgba(40, 66, 99, 0.85);}
.news-content .news-item span{float: right;color: rgba(0, 0, 0, 0.45);}
.news_more{font-size: 12px;font-family: 'PingFangSC-Regular';line-height: 18px;color: #0051BA;float: right;line-height: 40px;}
.title_more{float: right;font-size: 12px;color: #0E48A4;}
.title_more img{vertical-align: middle;margin-right: 0;margin-top: -5px;}
.hot_title2 .title_more{color: #fff;}
.news .hot_r{padding-top: 0;}
.news .hot_r .li-content .li-item {display: block;margin-bottom: 8px;overflow: hidden;}
.news .hot_r .li-content .li-item .order {float: left;display: block;height: 20px;width: 30px!important;line-height: 14px;background: rgba(0, 81, 186, 0.1);
    border-radius: 2px;font-size: 12px;font-family: 'PingFangSC-Regular, PingFang SC';font-weight: 400;color: #0051BA;padding: 3px 8px;text-align: center;
    margin-right: 4px;}
.news .hot_r .li-content .li-item a {float: left;display: block;font-family: 'PingFangSC-Regular, PingFang SC';font-size: 14px;color: rgba(40, 66, 99, 0.85);
    width: calc(100% - 35px);height: 22px;line-height: 22px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.news_type{padding: 20px 0 20px 0;margin-bottom: 50px;}
.flex{display: flex;justify-content: space-between;}
.type_li{width: 350px;margin-bottom: 10px;background: #fff;padding-bottom: 15px;}
.type_li ul{padding: 0 15px;}
.type_li li{position: relative;}
.type_li li a{display: block;height: 34px;line-height: 34px;font-size: 14px;color: #3E3E3E;padding-left: 20px;width: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.type_li li::before{content: '';width: 3px;height: 3px;border-radius: 50%;background: #0149A7;position: absolute;left: 0;top: 16px;}
.news_type .hot_title2{margin-bottom: 10px;}
.type_more{width: 348px;height: 48px;background: #0043AE;opacity: 1;border-radius: 0px;font-size: 16px;line-height: 48px;color: #fff;text-align: center;display: block;margin: 10px auto;}
.news .hot_l{position: relative;padding-top: 50px;width: 720px;}
        .news .hot_l .hot_title{position: absolute;left: 0;top: 0;width: 232px;
height: 45px;
background: #034D98;font-size: 16px;text-align: center;
font-family: PingFang SC;
line-height: 45px;
color: #FFFFFF;}
.news .hot_l ul li{width: 33%;float: left;text-align: center;margin-top: 20px;}
.news .hot_l ul li a img{width: 90%;}
.hot_title2{background: #fff;border-bottom: 1px solid #DBDBDB;height: 45px;line-height: 45px;}
.hot_title2 span{color: #0078D7;}
.hot_title2 .title_more{color: #0078D7;}
.news .hot_r .li-content .li-item{margin-bottom: 10px;}
.hot_r{padding-bottom: 24px;}
</style>
